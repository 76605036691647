import { User, UserRole, UserType } from '@wam/shared';

const userByLocation = {
  my: UserType.STUDENT,
  mentor: UserType.PARENT,
  teacher: UserType.TEACHER,
  admin: UserType.ADMIN,
  upstart: UserType.UPSTART,
};

export function determineUserType(url = window.location.href): UserType {
  let type: UserType;
  Object.keys(userByLocation).forEach((key: string) => {
    if (RegExp(`${key}\\\.`).test(url)) {
      type = userByLocation[key];
    }
  });
  localStorage.setItem('userType', type);
  return type || UserType.UNKNOWN;
}

export function isUpstartUserLogin(url = window.location.href): boolean {
  localStorage.setItem('upstartUserLogin', String(/upstart=true/.test(url)));
  return localStorage.getItem('upstartUserLogin') === 'true';
}

export function getFromLocalStorage(): User | null {
  try {
    const currentUser = localStorage.getItem('currentUser');
    return currentUser && JSON.parse(currentUser);
  } catch {
    return null;
  }
}

export function isParent(user?: User | UserType): boolean {
  if (typeof user === 'string') {
    return user === UserType.PARENT || user === UserType.UPSTART;
  }
  return user?.type === UserType.PARENT || user?.type === UserType.UPSTART;
}

export function isMentorParent(user?: User | UserType): boolean {
  if (typeof user === 'string') {
    return user === UserType.PARENT;
  }
  return user?.type === UserType.PARENT;
}

export function isUpstartParent(user?: User | UserType): boolean {
  if (typeof user === 'string') {
    return user === UserType.UPSTART;
  }
  return user?.type === UserType.UPSTART;
}

export function isStaff(user?: User | UserType): boolean {
  if (typeof user === 'string') {
    return user === UserType.TEACHER;
  }
  return user?.type === UserType.TEACHER;
}

export function isInternalAdminOrAccountAdminOrSupportEngineer(
  userType: UserType,
  userRoles: UserRole[],
): boolean {
  return (
    userType === UserType.ADMIN &&
    (userRoles?.includes(UserRole.INTERNAL_ADMIN) ||
      userRoles?.includes(UserRole.ACCOUNT_ADMIN) ||
      userRoles?.includes(UserRole.SUPPORT_ENGINEER))
  );
}
